export const urlConfig = {
  signupURL: "https://signup.organogram.app",
  apiUrl: "https://api.people.organogram.app/api/v1/",
  aiApiUrl: "https://ai.organogram.app/api/v1",
  mySpaceUrl: "https://myspace.organogram.app",
  payrollUrl: "https://payroll.organogram.app",
  hrUrl: "https://people.organogram.app",
  adminUrl: "https://admin.organogram.app",
  workspaceTrends: "https://trends.organogram.app/api/v1/trends",
};
