import "@mdxeditor/editor/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./utils/reportWebVitals";

import App from "./App";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthService } from "./services/auth";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const renderApp = () =>
  root.render(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>,
  );

AuthService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
